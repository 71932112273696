import { FormControl, FormLabel, Popover } from '@mui/material';
import './index.scss';
import { useState } from 'react';

interface IFormControlCustom {
  isMarked?: boolean | undefined;
  label?: string | undefined;
  oldOrderId?: string | undefined;
  listOldOrderId?: any[] | undefined;
  isDisabled?: boolean | undefined;
  className?: string | undefined;
  classNameLabel?: string | undefined;
  children: React.ReactNode;
  fullWidth?: boolean | undefined;
}

const FormControlCustom = (props: IFormControlCustom) => {
  const { isMarked = false, label, oldOrderId, listOldOrderId, isDisabled = false, className, classNameLabel, children, ...rest } = props;

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  console.log('listOldOrderId', oldOrderId, ((listOldOrderId ?? []).length == 0 && oldOrderId) ? 'a' : 'b', (listOldOrderId ?? []).length);

  return (
    <FormControl className={className || 'form-control'} {...rest}>
      <FormLabel
        className={`${classNameLabel} ${isDisabled ? 'text-grey-2' : 'text-secondary'}  font-medium mb-10 label`}
      >
        {label}
        {isMarked ? <label className="text-red"> * </label> : ''}
        {((listOldOrderId ?? []).length == 0 && oldOrderId) ? <> <a className="text-link" target='_blank' href={`https://basso.vn/basso/customer_order/detail/${oldOrderId}`} >(Xem đơn order) </a></> : <> </>}
        {(listOldOrderId ?? []).length > 0 ? <>
          <a className="text-link" target='_blank' onClick={handleClick} >(Xem đơn order) </a>
          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
          >
            <div style={{ display: 'block', width: '150px' }}>
              {(listOldOrderId ?? []).map((item, index) => {
                return <a style={{
                  display: 'block',
                  height: '30px',
                  textAlign: 'center',
                  alignContent: 'center',
                }} key={index} className="text-link" target='_blank' href={`https://basso.vn/basso/customer_order/detail/${item?.id}`} >{item?.orderNumber} </a>
              })}
            </div>
          </Popover>
        </> : <> </>}
      </FormLabel>
      {children}
    </FormControl>
  );
};

export default FormControlCustom;
