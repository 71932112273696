import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import Paper from '@mui/material/Paper';
import { isEmpty } from 'lodash';
import { useEffect, useState } from 'react';

import Collapse from '@mui/material/Collapse';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { useToast } from 'src/shared-components/Toast';

import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import noData from 'src/assets/no-data.png';

import { Button, Grid } from '@mui/material';
import { checkStatusOrderDetail } from 'src/functions/order-statuts';
import { convertCurrency } from 'src/utils/utils';
import './index.scss';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import CheckIcon from '@mui/icons-material/Check';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { addProductInOrder } from 'src/services/order.servies';
import { useParams } from 'react-router-dom';

function MuiTableRow(props: any) {
  const {
    rows,
    row,
    length,
    selectedRow,
    deleteProduct,
    type,
    statusType,
    textNote,
    onPressNote,
    onChangeQuantity,
    getIdItem,
    onChangeSkuQuantity,
    shippingOrders,
  } = props;
  const [open, setOpen] = useState<boolean>(false);
  const [exampleState, setExampleState] = useState<string>(row?.note);
  const [quantity, setQuantity] = useState<number>(row?.quantity);
  const [openConfirmSaveQuantity, setOpenConfirmSaveQuantity] = useState<boolean>(false);
  const user = JSON.parse(localStorage.getItem('user') || '{}');
  const params = useParams();
  const queryClient = useQueryClient();
  const toast = useToast();

  const updateProductMutation = useMutation({
    mutationFn: addProductInOrder,
    onSuccess: (data: any) => {
      toast('success', 'Cập nhật số lượng thành công');
      window.location.reload();
      // queryClient.invalidateQueries({ queryKey: ['detailProduct'] });
    },
    onError: (error: any) => {
      if (error.response.status === 403) {
        toast('error', 'Bạn không có quyền thực hiện');
      } else {
        toast('error', error.response.data.message);
      }
    },
  });

  const onUpdateQuantity = () => {
    updateProductMutation.mutate({
      id: params?.id,
      productId: row?.product?.id,
      quantity: quantity - row.quantity,
      user: user?.username,
    });
  };

  const onChange = (event: any) => {
    setExampleState(event.target.value);
    textNote(event.target.value);
  };

  const onChangeQuantityInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    let value = e.target.value.replaceAll(/\D/gi, '');
    setQuantity(Math.min(+row?.product?.quantity + row?.quantity, +value || 1));
  };

  const onChangeQuantityByPayload = (type: 'add' | 'minus') => () => {
    if (type === 'add') {
      setQuantity(pre => Math.min(++pre, +row?.product?.quantity + row.quantity));
    } else {
      setQuantity(pre => Math.max(--pre, 1));
    }
  };

  useEffect(() => {
    const resizeFunc = () => {
      const viewField = document.getElementsByClassName('view-field');
      if (viewField.length) {
        const firstField = viewField[0];
        const secField = viewField[1];
        (secField as HTMLElement).style.width = `${(firstField as HTMLElement)?.offsetWidth}px`;
      }
    };
    resizeFunc();
    window.addEventListener('resize', resizeFunc);
    return () => {
      window.removeEventListener('resize', resizeFunc);
    };
  }, []);

  return (
    <>
      <TableRow
        hover
        key={row?.productId + length}
        sx={{
          '& .MuiTableBody-root:hover': {
            backgroundColor: '#76B8CD1A',
          },
          '& .MuiTableRow-selected': {
            backgroundColor: '#76b8cd1a',
          },
        }}
      >
        <TableCell
          align="center"
          component="th"
          scope="row"
          onClick={() => {
            setOpen(!open);
          }}
        >
          {length + 1}
        </TableCell>

        <TableCell
          component="th"
          scope="row"
          padding="none"
          align="center"
          onClick={() => {
            setOpen(!open);
          }}
        >
          <div
            style={{
              alignItems: 'center',
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <img
              src={type === 'detail' ? row?.product?.images?.[0] : row?.images?.[0] ?? row?.product?.images?.[0]}
              alt=""
              width="50"
              height="50"
              style={{ margin: 10, aspectRatio: 0.7, height: undefined }}
            />
          </div>
        </TableCell>
        <TableCell
          align="center"
          style={{ wordBreak: 'break-word' }}
          onClick={() => {
            setOpen(!open);
          }}
        >
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <span>{type === 'detail' ? row?.product?.name : row?.name ?? row?.product?.name}</span>
            <span style={{ color: '#656565', fontWeight: '600', fontSize: '0.785rem' }}>
              {type === 'detail' && checkStatusOrderDetail(row?.status).status}
            </span>
          </div>
        </TableCell>
        {type !== 'detail' ? (
          <TableCell
            style={{ wordBreak: 'break-word' }}
            onClick={() => {
              setOpen(!open);
            }}
          >
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              {row?.variations
                ? row?.variations?.map((varia: any) => {
                    if (!isEmpty(varia?.value)) {
                      return (
                        <span key={varia?.name}>
                          {varia?.name}: {varia?.value}
                        </span>
                      );
                    }
                  })
                : row?.product?.productVariations?.map((varia: any) => {
                    if (!isEmpty(varia?.value)) {
                      return (
                        <span key={varia?.name}>
                          {varia?.name}: {varia?.value}
                        </span>
                      );
                    }
                  })}
              {row?.productVariations &&
                row?.productVariations?.map((varia: any) => {
                  if (!isEmpty(varia?.value)) {
                    return (
                      <span key={varia?.name}>
                        {varia?.name}: {varia?.value}
                      </span>
                    );
                  }
                })}
            </div>
          </TableCell>
        ) : (
          <TableCell
            style={{ wordBreak: 'break-word' }}
            onClick={() => {
              setOpen(!open);
            }}
          >
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              {row?.product?.productVariations?.map((varia: any) => {
                if (!isEmpty(varia?.value)) {
                  return (
                    <span key={varia?.name}>
                      {varia?.name}: {varia?.value}
                    </span>
                  );
                }
              })}
            </div>
          </TableCell>
        )}
        <TableCell align="center" style={{ wordBreak: 'break-word' }}>
          <Box display={'flex'} justifyContent={'center'} alignItems={'center'}>
            <IconButton size="small" onClick={onChangeQuantityByPayload('minus')}>
              <RemoveIcon />
            </IconButton>
            <Box width={'50px'} style={{ border: '1px solid #d7d7d7', padding: '5px 0', borderRadius: '5px' }}>
              <input
                type="text"
                name="quantity"
                value={quantity}
                onChange={onChangeQuantityInput}
                style={{
                  maxWidth: '100%',
                  border: 'none',
                  textAlign: 'center',
                  outline: 'none',
                  backgroundColor: 'transparent',
                }}
              />
            </Box>
            <IconButton size="small" onClick={onChangeQuantityByPayload('add')}>
              <AddIcon />
            </IconButton>
          </Box>
          <Box display={'flex'} justifyContent={'center'} alignItems={'center'} gap={'4px'}>
            <Typography fontSize={'14px'}>Kho: </Typography>
            <Typography fontSize={'14px'}>{row?.product?.quantity}</Typography>
          </Box>
        </TableCell>
        <TableCell
          align="center"
          onClick={() => {
            setOpen(!open);
          }}
        >
          {convertCurrency(type === 'copy' ? row?.price || 0 : row?.price || 0)}
        </TableCell>
        <TableCell
          align="center"
          onClick={() => {
            setOpen(!open);
          }}
        >
          {type === 'copy'
            ? convertCurrency(Number(row?.price) * Number(row?.quantity) || 0)
            : convertCurrency(Number(row?.price) * row?.quantity) || 0}
        </TableCell>

        {(statusType === 'IN_REVIEW' || type === 'order' || type === 'copy' || statusType === 'IN_PROCESS') && (
          <TableCell align="center">
            <div
              style={{
                alignItems: 'center',
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <Button
                size="small"
                style={{ backgroundColor: 'transparent' }}
                onClick={() => deleteProduct(row?.id, length)}
              >
                <DeleteForeverOutlinedIcon style={{ color: '#AFAFAF' }} />
              </Button>
              {quantity !== row?.quantity && (
                <>
                  <Tooltip title="Cập nhật số lượng">
                    <IconButton size="small" color="primary" onClick={() => setOpenConfirmSaveQuantity(true)}>
                      <CheckIcon />
                    </IconButton>
                  </Tooltip>
                  <Dialog
                    open={openConfirmSaveQuantity}
                    onClose={() => setOpenConfirmSaveQuantity(false)}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                  >
                    <DialogTitle id="alert-dialog-title">Xác nhận cập nhật số lượng sản phẩm?</DialogTitle>
                    <DialogContent>
                      <DialogContentText id="alert-dialog-description">
                        <Grid container direction={'column'} gap={'10px'}>
                          <Grid item md={6}>
                            <Typography>{row?.product?.name}</Typography>
                          </Grid>
                          <Grid item md={6}>
                            <Typography>
                              {`${quantity > row?.quantity ? 'Tăng' : 'Giảm'} Từ ${row?.quantity} thành ${quantity}`}
                            </Typography>
                          </Grid>
                        </Grid>
                      </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                      <Button variant="outlined" onClick={() => setOpenConfirmSaveQuantity(false)}>
                        Hủy
                      </Button>
                      <Button
                        variant="contained"
                        onClick={() => {
                          setOpenConfirmSaveQuantity(false);
                          onUpdateQuantity();
                        }}
                        autoFocus
                      >
                        Xác nhận
                      </Button>
                    </DialogActions>
                  </Dialog>
                </>
              )}
            </div>
          </TableCell>
        )}
      </TableRow>
      <>
        <TableRow key={row?.productId} style={{ width: '100%' }}>
          <TableCell style={{ padding: 0 }} colSpan={8} align="center">
            <Collapse in={open} timeout="auto">
              <div style={{}} className="class-name-view">
                <Grid container className={'align-items-center header_title'}>
                  <Grid item md={12}>
                    <Box className={'header-button'}>
                      <div
                        style={{
                          flexDirection: 'row',
                          display: 'flex',
                          padding: '10px',
                          alignItems: 'center',
                        }}
                      >
                        <span className="title-product">Mã sản phẩm </span>
                        <div className="view-field">
                          <span className="product-txt">{row?.sku || row?.product?.skuSub}</span>
                        </div>
                      </div>
                      <div style={{ flexDirection: 'row', display: 'flex', alignItems: 'center', padding: '10px' }}>
                        <span className="title-product">Tình trạng </span>
                        <div className="view-field">
                          {
                            <span className="product-txt">
                              {type !== 'copy' ? checkStatusOrderDetail(row?.status).status : '..'}
                            </span>
                          }
                        </div>
                      </div>
                      <div
                        style={{
                          flexDirection: 'row',
                          display: 'flex',
                          alignItems: 'center',
                          padding: '10px',
                        }}
                      >
                        <span className="title-product">Ghi chú</span>
                        <TextField
                          value={exampleState}
                          onChange={onChange}
                          className="input-field-detail-fix"
                          multiline
                          maxRows={3}
                          variant="outlined"
                        />
                        <Button
                          style={{
                            marginLeft: '10px',
                            backgroundColor: 'transparent',
                            border: '1px solid #d7d7d7',
                            padding: '0.03rem 0.4rem',
                          }}
                          onClick={() => onPressNote(row.id, length)}
                        >
                          <span style={{ color: '#5f5f5f', textTransform: 'none' }}>Lưu lại</span>
                        </Button>
                      </div>
                    </Box>
                  </Grid>
                </Grid>
              </div>
            </Collapse>
          </TableCell>
        </TableRow>
      </>
    </>
  );
}

interface expandProps {
  columns: any;
  rows: any;
  deleteProduct?: any;
  type?: string;
  statusType?: string;
  onPressNote?: any;
  textNote?: any;
  onChangeNote?: any;

  onChangeQuantity?: any;
  getIdItem?: any;
  onChangeSkuQuantity?: any;
  shippingOrders?: any;
}

export default function ProductList(props: expandProps) {
  const {
    columns,
    rows,
    deleteProduct,
    type,
    statusType,
    onPressNote,
    textNote,
    onChangeNote,
    onChangeQuantity,
    getIdItem,
    onChangeSkuQuantity,
    shippingOrders,
  } = props;
  const [rowsPerPage, setRowsPerPage] = useState<any>(10);

  return (
    <>
      <TableContainer component={Paper} className="expand-table-order">
        <Table aria-label="collapsible table" className="mobile-scroll">
          <TableHead>
            <TableRow>
              {columns?.map((col: any, i: number) => {
                return (
                  <TableCell align={'center'} key={col.field} style={{ padding: '0px 15px' }}>
                    {col.headerName}
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows?.map((row: any, index: number) => (
              <MuiTableRow
                rows={rows}
                key={row?.productId}
                row={row}
                length={index}
                deleteProduct={deleteProduct}
                type={type}
                statusType={statusType}
                onPressNote={onPressNote}
                textNote={textNote}
                onChangeNote={onChangeNote}
                onChangeQuantity={onChangeQuantity}
                getIdItem={getIdItem}
                onChangeSkuQuantity={onChangeSkuQuantity}
                shippingOrders={shippingOrders}
              />
            ))}
          </TableBody>
        </Table>
        {isEmpty(rows) && (
          <div className={'btn-add-product'}>
            <div className={'view-btn-add'}>
              <img src={noData} className="empty-data" />
              <span>Không có sản phẩm</span>
            </div>
          </div>
        )}
      </TableContainer>
    </>
  );
}
